import React, { Suspense } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./store";

const MarketPlace = React.lazy(() => import('./views/market-place'))
const Buy = React.lazy(() => import('./views/buy/buy'))
const Sell = React.lazy(() => import('./views/sell/sell'))

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <MarketPlace /> },
    { path: "buy", element: <Buy /> },
    { path: "buy/:id", element: <Buy /> },
    { path: "sell/:id", element: <Sell /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Suspense fallback={<></>}>
        <Router basename="/">
          <App />
        </Router>
      </Suspense>
    </Provider>
  );
};

export default AppWrapper;