// import constant
import {
    MENU_CATEGORY
} from '../constant';

const initialState = {
    categoryList: []
};

const siteMenu = (state = initialState, action) => {
    switch (action.type) {
        case MENU_CATEGORY:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default siteMenu;